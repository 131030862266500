<template>
    <li
        class="relative m-0 md:ml-4 lg:ml-8 xl:ml-10 w-full md:w-auto"
        role="none"
        @mouseover="!isMobile ? openSubMenu() : null"
        @mouseleave="!isMobile ? menuOut() : null"
    >
        <a
            href="#"
            role="menuitem"
            ref="menuitem"
            @keydown.enter="menuItemEnter"
            @keydown.down="menuItemDown"
            aria-haspopup="true"
            :aria-expanded="isMenuOpen ? 'true' : 'false'"
            @click.prevent="isMobile ? toggleSubMenu() : null"
            :class="{'text-yellow' : isActive || isMenuOpen}"
            class="flex justify-end md:items-center md:h-20 text-18 md:text-16 lg:text-19 tracking-navlink transition duration-300 ease-out hover:no-underline hover:text-yellow mb-5 md:mb-0"
        >
            <span>{{title}}</span>
        </a>

        <ul
            :id="`${kebabTitle}-menu`"
            role="menu"
            :aria-label="title"
            ref="list"
            @keydown.down="menuDown"
            @keydown.up="menuUp"
            @keydown.left="menuLeft"
            @keydown.right="menuRight"
            @keydown.esc="menuEsc"
            @mouseover="!isMobile ? menuOver() : null"
            @mouseleave="!isMobile ? menuOut() : null"
            class="flex flex-wrap md:block relative md:absolute m-0 md:left-1/2 w-full max-w-md ml-auto md:w-40 list-none md:bg-yellow md:p-3 text-right md:text-center md:shadow-md mb-10 md:mb-0"
            :class="{'open' : isMenuOpen}"
        >
            <!-- class="grid grid-cols-2 gap-5 md:block md:grid-cols-none md:gap-0 relative md:absolute m-0 md:left-1/2 w-full max-w-md ml-auto md:w-40 list-none md:bg-yellow md:p-3 text-right md:text-center md:shadow-md mb-10 md:mb-0" -->
            <slot></slot>
        </ul>
    </li>
</template>

<script>
export default {
    name: 'HeaderNavItem',
    menuItems: [],
    closeTimer: null,

    props: {
        title: {
            type: String,
            default: null
        },
        isActive: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            isMenuOpen: false,
            closeDelay: 100, // In milliseconds,
            isMobile: false
        };
    },

    computed: {
        kebabTitle() {
            let t = this.title;
            return t.replace(/\s+/g, '-').toLowerCase();
        }
    },

    methods: {
        openSubMenu(event) {
            this.clearTimer();
            this.isMenuOpen = true;
        },

        closeSubMenu() {
            this.isMenuOpen = false;
            this.setMenuItemsTabIndex(-1);
        },

        toggleSubMenu(event) {
            this.isMenuOpen = !this.isMenuOpen;
        },

        menuOut() {
            this.startTimer();
        },

        menuOver() {
            this.clearTimer();
        },

        startTimer() {
            this.closeTimer = setTimeout(
                () => this.closeSubMenu(),
                this.closeDelay
            );
        },

        clearTimer() {
            clearTimeout(this.closeTimer);
        },

        // When focus is on a menuitem that has a submenu, ENTER opens the submenu and places focus on its first item.
        menuItemEnter(event) {
            this.keyboardMenuOpen();
            event.preventDefault();
        },

        // When focus is on a menuitem in a menubar (level 1), DOWN opens its submenu and places focus on the first item in the submenu.
        menuItemDown(event) {
            console.log('menuItemDown');
            if (this.isMenuOpen === false) {
                this.keyboardMenuOpen();
                event.preventDefault();
            }
        },

        // When focus is in a menu, DOWN moves focus to the next item, wrapping from the last to the first.
        menuDown(event) {
            if (this.isMenuOpen === false) {
                return;
            }

            const focusedElement = document.activeElement;
            for (let i = 0; i < this.menuItems.length; i++) {
                if (this.menuItems[i] === focusedElement) {
                    let newIndex = i + 1;
                    if (newIndex > this.menuItems.length - 1) {
                        this.menuItems[0].focus();
                    } else {
                        this.menuItems[newIndex].focus();
                    }
                }
            }

            event.preventDefault();
        },

        // When focus is in a menu, UP moves focus to the previous item, optionally wrapping from the first to the last.
        menuUp(event) {
            if (this.isMenuOpen === false) {
                return;
            }

            const focusedElement = document.activeElement;
            for (let i = 0; i < this.menuItems.length; i++) {
                if (this.menuItems[i] === focusedElement) {
                    let newIndex = i - 1;
                    if (newIndex < 0) {
                        this.menuItems[this.menuItems.length - 1].focus();
                    } else {
                        this.menuItems[newIndex].focus();
                    }
                }
            }

            event.preventDefault();
        },

        // When focus is in a menu and on an item that does not have a submenu, RIGHT performs the following 3 actions:
        // * Closes the submenu and any parent menus
        // * Moves focus to the next menuitem in the menubar
        // * (Not implemented) opens the submenu of that menuitem without moving focus into the submenu
        menuRight(event) {
            if (this.isMenuOpen === true) {
                this.closeSubMenu();
                this.$refs.menuitem.focus();
                event.preventDefault();
                // NOTE: Event bubbling will close any parent submenus and continue up to the menubar,
                // which will select the next menubar item
            }
        },

        // When focus is in a submenu of an item in a menu, LEFT closes the submenu and returns focus to the parent menuitem.
        menuLeft(event) {
            if (this.isMenuOpen === true) {
                this.closeSubMenu();
                this.$refs.menuitem.focus();
                if (this.level === 2) {
                    // STOP propagation so only this submenu will close
                    event.stopPropagation();
                }
                event.preventDefault();
            }
        },

        // ESCAPE Closes the menu that contains focus and return focus to the element or context, e.g., menu button or parent menuitem, from which the menu was opened.
        menuEsc(event) {
            if (this.isMenuOpen === true) {
                this.closeSubMenu();
                this.$refs.menuitem.focus();
                event.stopPropagation();
            }
        },

        // Open a menu and set focus on the first item
        keyboardMenuOpen() {
            if (this.isMenuOpen === true) {
                return;
            }

            this.openSubMenu();

            this.setMenuItemsTabIndex(0);

            // $nextTick doesn't work here consistently
            setTimeout(() => {
                this.menuItems[0].focus();
            }, 100);
        },

        setMenuItemsTabIndex(tabIndex) {
            for (let i = 0; i < this.menuItems.length; i++) {
                this.menuItems[i].tabIndex = tabIndex;
            }
        },

        mediaQuery() {
            this.isMobile = window.matchMedia('(max-width: 767px)').matches;
        }
    },

    created() {
        window.addEventListener('resize', this.mediaQuery);

        this.mediaQuery();
    },

    beforeDestroy() {
        window.removeEventListener('resize', this.mediaQuery);
    },

    mounted() {
        console.log('HeaderNavItem mounted');

        setTimeout(() => {
            this.menuItems = this.$el.querySelectorAll(
                `#${this.kebabTitle}-menu > li > a`
            );

            this.setMenuItemsTabIndex(-1);
        }, 500);
    }
};
</script>

<style scoped>
ul[role='menu'] {
    display: none;
}

ul[role='menu'].open {
    display: flex;
}

@screen md {
    ul[role='menu'] {
        display: block;
        visibility: hidden;
        opacity: 0;
        transform: translate3d(-50%, 5px, 0);
        transition: visibility 0s cubic-bezier(0, 0, 0.2, 1) 0.2s,
            opacity 0.2s cubic-bezier(0, 0, 0.2, 1) 0s,
            transform 0.2s cubic-bezier(0, 0, 0.2, 1) 0s;
    }

    ul[role='menu'].open {
        visibility: visible;
        opacity: 1;
        transform: translate3d(-50%, 0, 0);
        transition: visibility 0s cubic-bezier(0, 0, 0.2, 1) 0s,
            opacity 0.2s cubic-bezier(0, 0, 0.2, 1) 0s,
            transform 0.2s cubic-bezier(0, 0, 0.2, 1) 0s;
    }
}
</style>
