<template>
    <div
        class="relative flex flex-row flex-wrap md:flex-no-wrap justify-between items-center max-w-6xl mx-auto"
    >
        <div class="flex items-center justify-between w-full md:w-40 h-20 px-6">
            <div class="relative">
                <slot name="logo"></slot>
            </div>
            <button
                @click="isOpen = !isOpen"
                type="button"
                class="flex flex-col items-center w-12 mt-1 font-small-caps text-gray-light tracking-wider transition duration-200 ease-out hover:text-white focus:outline-none md:hidden"
            >
                <svg
                    class="stroke-current w-7 -mt-1"
                    enable-background="new 0 0 40 40"
                    viewBox="0 0 40 40"
                >
                    <g fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="4">
                        <path
                            d="m2 8h36"
                            class="transform origin-20 transition-transform duration-200 ease-in-out"
                            :class="isOpen ? 'rotate-45 -translate-x-1' : 'rotate-0'"
                        />
                        <path
                            d="m2 20h36"
                            class="transform transition-transform duration-200 ease-in-out"
                            :class="isOpen ? 'opacity-0' : 'opacity-100'"
                        />
                        <path
                            d="m2 32h36"
                            class="transform origin-20 transition-transform duration-200 ease-in-out"
                            :class="isOpen ? '-rotate-45 -translate-x-1' : 'rotate-0'"
                        />
                    </g>
                </svg>
                <span
                    class="flex-1 font-semibold text-11 leading-tight mt-1"
                    v-html="isOpen ? 'CLOSE' : 'MENU'"
                ></span>
            </button>
        </div>
        <nav
            aria-label="Dreamfields Foods"
            :class="isOpen ? 'translate-y-0' : '-translate-y-full'"
            class="absolute md:relative top-0 -z-1 md:z-0 w-full md:w-auto mt-20 md:mt-0 bg-black transform transition duration-500 ease-in-out md:translate-y-0"
        >
            <slot></slot>
        </nav>
    </div>
</template>

<script>
export default {
    props: ['siteUrl'],
    data() {
        return {
            isOpen: false
        };
    }
};
</script>
