import styles from './styles/app.css';

import Vue from 'vue';

import AOS from 'aos';
import 'aos/dist/aos.css';

Vue.config.productionTip = false;
import HeaderNav from './components/HeaderNav.vue';
import HeaderNavItem from './components/HeaderNavItem.vue';

const components = {
    HeaderNav,
    HeaderNavItem,
    RecipeFilterButton: () =>
        import(
            /* webpackChunkName: "recipefilter" */ './components/RecipeFilterButton'
        ),
    ExpandablePanel: () =>
        import(
            /* webpackChunkName: "expandpanel" */ './components/ExpandablePanel'
        ),
    StoryCarousel: () =>
        import(/* webpackChunkName: "carousel" */ './components/StoryCarousel'),
    StoreLocator: () =>
        import(/* webpackChunkName: "locator" */ './components/StoreLocator'),
    ContactFormPage: () =>
        import(
            /* webpackChunkName: "contactform" */ './components/ContactFormPage'
        ),
    VideoPlayer: () =>
        import(
            /* webpackChunkName: "videoplayer" */ './components/VideoPlayer'
        ),
    DestiniEmbed: () =>
        import(
            /* webpackChunkName: "destiniEmbed" */ './components/DestiniEmbed'
        )
};
import axios from 'axios';
new Vue({
    el: '#app',
    components,

    mounted() {},
    created() {
        AOS.init({
            once: true,
            offset: 300,
            duration: 600,
            delay: 150,
            easing: 'ease-out-cubic'
        });
    }
});
